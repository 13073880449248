import { createRouter, createWebHistory } from 'vue-router'

import { util } from '@/Helpers'

import { useAuthStore } from '@/Stores'
import { useNProgress } from '@vueuse/integrations/useNProgress'

import Login from '@/Pages/Auth/Login.vue'
import Feedback from '@/Pages/Feedback.vue'
import FeedbackSingle from '@/Pages/FeedbackSingle.vue'
import MapNearby from '@/Pages/MapNearby.vue'
import ClientContacts from '@/Pages/ClientContacts.vue'
import ProfileClient from '@/Pages/ClientProfile.vue'
import ProfileCaregiver from '@/Pages/CaregiverProfile.vue'
import CreateNewClient from '@/Pages/ClientCreateNew.vue'
import ClientSchedule from '@/Pages/ClientSchedule.vue'

// import { useNotificationStore } from '@/Stores'

const baseTitle = ' - LifeWorx Latte'


const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    },
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            redirect: to => {
                return { path: '/feedback' }
            },
        },
        {
            path: "/login",
            name: "login",
            component: Login,
            meta: {
                title: 'Login'
            }
        },
        {
            path: "/feedback",
            name: "feedback",
            component: Feedback,
            meta: {
                title: 'Client→Caregiver Feedback'
            }
        },
        {
            path: "/feedback/caregiver/:caregiver(\\d+)",
            name: "feedback.caregiver",
            component: FeedbackSingle,
            props: true,
            meta: {
                title: 'Feedback by Caregiver'
            }
        },
        {
            path: "/feedback/client/:client(\\d+)",
            name: "feedback.client",
            component: FeedbackSingle,
            props: true,
            meta: {
                title: 'Feedback by Client'
            }
        },
        {
            path: "/embedded/client/nearby",
            name: "nearby.address",
            component: MapNearby,
            props: true,
            meta: {
                title: 'Who\'s Nearby'
            }
        },
        {
            path: "/embedded/client/:coffee_client_user_profile_id(\\d+)/contacts",
            name: "client.contacts",
            component: ClientContacts,
            props: true,
            meta: {
                title: 'Client Contacts'
            }
        },
        {
            path: "/embedded/client/:coffee_client_user_profile_id(\\d+)/schedule",
            name: "client.schedule",
            component: ClientSchedule,
            props: true,
            meta: {
                title: 'Client Schedule'
            }
        },
        {
            path: "/client/create",
            name: "client.create",
            component: CreateNewClient,
            props: true,
            meta: {
                title: 'Create New Client'
            }
        },
        {
            path: "/client/:client(\\d+)",
            name: "client.profile",
            component: ProfileClient,
            props: true,
            meta: {
                title: 'Client Profile'
            }
        },
        {
            path: "/caregiver/:caregiver(\\d+)",
            name: "client.caregiver",
            component: ProfileCaregiver,
            props: true,
            meta: {
                title: 'Caregiver Profile'
            }
        },
    ],
})

router.beforeEach((to, from, next) => {
    const { isLoading } = useNProgress()
    const { auth } = useAuthStore()
    isLoading.value = true
    auth.loading = true

    next()
})

router.beforeEach((to, from, next) => {
    if (to && to.name && next) {
        // document.title = util.titleCase(to.name.replace('-', ' ')) + baseTitle
        document.title = to.meta.title + baseTitle
        // TODO handle subroute naming here and figure out how to prepend subpage titles from component data or props
        next()
    }
})

router.beforeEach(async (to) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const { auth } = useAuthStore();
    if (auth.authenticated && to.path == '/login') {
        return {
            path: '/'
        }
    }
    if (authRequired && (!auth.authenticated)) {
        return {
            path: '/login',
            query: { returnUrl: to.href }
        }
    }

    // if (to.params.period && isNaN(to.params.period)) {
    //     const notificationStore = useNotificationStore()
    //     notificationStore.add('Error 404', 'error', 'Invoice not found')
    //     return {
    //         path: '/'
    //     }
    // }
})

router.afterEach(() => {
    useNProgress(1.0)
    const { auth } = useAuthStore()
    auth.loading = false
})

export default router;
