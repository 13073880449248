<script setup>
    import { ref, watch } from 'vue';

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        id: {
            type: String,
            required: true
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'text-lifeworx-blue-800'
        }
    })

    const isChecked = ref(props.modelValue)

    watch(() => props.modelValue, (newVal) => {
        isChecked.value = newVal;
    });

    const emitChange = () => {
        emit('update:modelValue', isChecked.value);
    };

</script>

<template>
    <label
        :for="`grid-stay-type-${props.id}`"
        :class="[
            'text-sm font-semibold group whitespace-nowrap',
            {
                'opacity-50': props.disabled,
                'cursor-pointer': !props.disabled
            },
            color
        ]"
    >
        <input
            :id="`grid-stay-type-${props.id}`"
            type="checkbox"
            v-model="isChecked"
            @change="emitChange"
            :disabled="props.disabled"
            :class="[
                'mr-1 rounded cursor-pointer dark:bg-gray-900 border-stone-400 dark:border-gray-700 text-lifeworx-blue-600 shadow-sm focus:ring-lifeworx-blue-500 dark:focus:ring-lifeworx-blue-600 dark:focus:ring-offset-gray-800 ',
                {
                    'opacity-50': props.disabled,
                    'cursor-pointer' : !props.disabled
                }
            ]"
        >
        {{ props.label }}
    </label>
</template>
