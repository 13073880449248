<script setup>
    import { computed, ref, watch } from 'vue'
    import { storeToRefs } from 'pinia'
    import LatteCaregiverScheduleView from '@/Components/Caregiver/LatteCaregiverScheduleView.vue';

    import { useCaregiverClientShiftStore, useCaregiverInvoiceShiftStore, useFormStatusStore } from '@/Stores'
    import { debounce, getModifiedProperties, util } from '@/Helpers';
    import LatteCaregiverScheduleBuilder from '@/Components/Caregiver/LatteCaregiverScheduleBuilder.vue';
    import LatteCaregiverScheduleFooter from '@/Components/Caregiver/LatteCaregiverScheduleFooter.vue';

    const formStatus = useFormStatusStore()

    const caregiverClientShiftStore = useCaregiverClientShiftStore()
    const caregiverInvoiceShiftStore = useCaregiverInvoiceShiftStore()

    const formAvailabilityDayLoading = formStatus.computedFormLoading('caregiverAvailabilityForm')
    const formAvailabilityPrefLoading = formStatus.computedFormLoading('caregiverAvailabilityPrefForm')

    const emit = defineEmits(['caregiver:change'])

    const scheduleState = ref(null)

    const props = defineProps({
        caregiver : {
            type: Object,
            required: true
        },
        schedule_start_date: {
            type: String,
            required: true
        }
    })

    function handleScheduleChange(data) {
        // Extract difference for saving
        scheduleState.value = data
    }

    function handlePreferenceChange(data) {
        const diff = getModifiedProperties(
            JSON.stringify(data),
            JSON.stringify(props.caregiver),
            [
                'availability_pref_live_in', 'availability_pref_live_out', 'availability_pref_day_work',
                'availability_pref_night_work',
                'availability_pref_fill_ins_flexible',
                'availability_pref_max_travel_time',
                'availability_pref_min_hourly_rate',
                'availability_pref_min_daily_rate',
                'availability_pref_live_in_min_days_per_week',
                'availability_pref_live_in_max_days_per_week',
                'availability_pref_live_out_min_days_per_week',
                'availability_pref_live_out_max_days_per_week',
                'availability_pref_live_out_min_hours_per_day',
                'availability_pref_live_out_max_hours_per_day',
                'availability_notes',
            ]
        )

        if (Object.keys(diff).length) {
            emit('caregiver:change', diff, 'caregiverAvailabilityPrefForm')
        }
    }

    function handleLastConfirmedDateUpdate(date) {
        if (date) {
            emit('caregiver:change', {
                last_confirmed_date: date
            }, 'caregiverAvailabilityPrefForm')
        }
    }

    function handleScheduleConfirm() {

    }

    function retrieveShifts() {
        if (!props.caregiver.id) {
            return
        }

        if (props.schedule_start_date >= util.date.lastSunday()) {
            const upperBoundDate = util.date.nextDate(props.schedule_start_date, 7 * 5)
            caregiverClientShiftStore.retrieveShifts(props.caregiver.id, props.schedule_start_date, upperBoundDate, 'caregiverAvailabilityForm')
                .then(response => {
                    caregiverClientShiftStore.replaceLocalStoreShifts(props.caregiver.id, response.data)
                })
        } else {
            const upperBound = util.date.nextDate(props.schedule_start_date, 6)
            caregiverInvoiceShiftStore.retrieveShifts(props.caregiver.id, props.schedule_start_date, upperBound, 'caregiverAvailabilityForm')
                .then(response => {
                    caregiverInvoiceShiftStore.replaceLocalStoreShifts(props.caregiver.id, response.data)
                })
        }

    }

    const retrieveShiftDebounce = debounce(() => {
        retrieveShifts()
    }, 300)

    watch([() => props.schedule_start_date, () => props.caregiver.id], (newValue) => {
        retrieveShiftDebounce()
    }, {immediate: true, deep: true})

    const liveInShifts = computed(() => {
        if (scheduleState.value == null) {
            return []
        }

        const liveInData = scheduleState.value.live_in[0]

        return liveInData.day_of_week.map(day => ({
            start_time: liveInData['start_time'],
            duration: liveInData['duration'],
            start_date: day != 'saturday' ? util.date.nextDayOfWeekDate(props.schedule_start_date, day) : util.date.previousDate(props.schedule_start_date),
            day_of_week: day,
            stay_type: 'live_in'
        }))
    })

    const liveOutGroupOneShifts = computed(() => {
        if (scheduleState.value == null) {
            return []
        }

        const liveOutData = scheduleState.value.live_out[0]

        return liveOutData.day_of_week.map(day => ({
            start_time: liveOutData['start_time'],
            duration: liveOutData['duration'],
            start_date: day != 'saturday' ? util.date.nextDayOfWeekDate(props.schedule_start_date, day) : util.date.previousDate(props.schedule_start_date),
            day_of_week: day,
            stay_type: 'live_out'
        }))
    })

    const liveOutGroupTwoShifts = computed(() => {
        if (scheduleState.value == null) {
            return []
        }

        const liveOutData = scheduleState.value.live_out[1]

        return liveOutData.day_of_week.map(day => ({
            start_time: liveOutData['start_time'],
            duration: liveOutData['duration'],
            start_date: day != 'saturday' ? util.date.nextDayOfWeekDate(props.schedule_start_date, day) : util.date.previousDate(props.schedule_start_date),
            day_of_week: day,
            stay_type: 'live_out'
        }))
    })

</script>

<template>
    <div class="relative">
        <div class="z-content" id="caregiver_schedule">
            <LatteCaregiverScheduleBuilder
                v-if="props.schedule_start_date >= util.date.lastSunday()"
                :start_date="props.schedule_start_date"
                :schedule="scheduleState"
                :caregiver="props.caregiver"
                @preference-data:change="handlePreferenceChange"
                @schedule-data:change="handleScheduleChange"
            />

            <LatteCaregiverScheduleView
                :caregiver_id="parseInt(props.caregiver.id)"
                :schedule_start_date="props.schedule_start_date"
                :formLoading="formAvailabilityDayLoading"
                :live_in_shifts="liveInShifts"
                :live_out_group_one_shifts="liveOutGroupOneShifts"
                :live_out_group_two_shifts="liveOutGroupTwoShifts"
            />

            <LatteCaregiverScheduleFooter
                :last_confirmed_date="caregiver.confirmed_at"
                @schedule:confirm="handleScheduleConfirm"
            />
        </div>
    </div>
</template>
