<script setup>
    import { ref, watch } from 'vue';
    import VueSlider from 'vue-3-slider-component';

    const value = ref([0, 100]); // Range values

    const props = defineProps({
        current_min: {
            type: [Number, String],
            value: 0
        },
        current_max: {
            type: [Number, String],
            value: 100
        },
        min: {
            type: [Number, String],
            default: 0
        },
        max: {
            type: [Number, String],
            default: 100
        }
    })

    watch(props, (newValue) => {
        value.value = [newValue.current_min, newValue.current_max]
    }, { immediate: true, deep: true})

    const emit = defineEmits(['update:current_min', 'update:current_max'])

    watch(value, (newValue, oldValue) => {
        if (newValue[0] != oldValue[0]) {
            emit('update:current_min', newValue[0])
        }

        if (newValue[1] != oldValue[1]) {
            emit('update:current_max', newValue[1])
        }
    })
</script>

<template>
    <vue-slider v-model="value" :min="props.min" :max="props.max" :range="true" />
</template>
