<script setup>

    import { ref, computed } from 'vue'
    import { useStorage } from '@vueuse/core'
    import { storeToRefs } from 'pinia'

    import { MinusIcon, QueueListIcon} from '@heroicons/vue/20/solid'

    import { useViewStore } from '@/Stores'
    import { util } from '@/Helpers'

    const viewStore = useViewStore()

    const props = defineProps({
        id: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: 'Untitled'
        },
        centerHeaderSlot: {
            type: Boolean,
            default: true
        },
        collapsible: {
            type: Boolean
        },
        start: {
            type: Number,
        },
        end: {
            type: Number
        },
        forceSingleLineMobileLayout: {
            type: Boolean,
            default: false
        },
        isEmbedded: {
            type: Boolean,
            default: false
        }
    })

    const collapsed = props.isEmbedded ? false : useStorage(props.id + '-collapsed', false)

    const { scrollDist } = storeToRefs(viewStore)

    const headerEl = ref(null)

    const viewportOffset = computed(() => {
        // forces recomputation using scrollDist
        return util.getOffset(headerEl.value).top + scrollDist.value - scrollDist.value;
    })

    const positionTop = computed(() => {
        if (!headerEl.value) { return false; }
        const navBarElHeight = document.getElementById('latte-navbar') ? document.getElementById('latte-navbar').offsetHeight : 0
        return viewportOffset.value-navBarElHeight;
    })

    const isStickied = computed(() => {
        if (collapsed == true || props.isEmbedded == true) { return false; }
        return ((positionTop.value-scrollDist.value) <= 0);
    })


</script>

<template>

    <div
        :id="props.id"
        :class="[
            'shadow-better',
            {
                'rounded-lg': collapsed,
                'my-4': props.isEmbedded,
                'my-12': !props.isEmbedded,
                'md:rounded-t-lg': !isStickied && !collapsed,
                'rounded-t-none': isStickied && !collapsed,
            }
        ]"
        ref="headerEl"
    >

        <div
            :class="[
                'flex md:h-12 md:items-start space-x-2 bg-lifeworx-blue-700 px-4 py-2.5 border-b border-lifeworx-blue-600 transition duration-200 top-16',
                {
                    'sticky': !props.isEmbedded,
                    'md:rounded-b-lg bg-lifeworx-blue-700 bg-opacity-70': collapsed,
                    'h-12': forceSingleLineMobileLayout,
                    'h-20': !forceSingleLineMobileLayout,
                    'md:rounded-t-lg z-module-container': !isStickied && !collapsed,
                    'rounded-t-none z-modal': isStickied && !collapsed,
                    'rounded-lg z-module-container': collapsed
                }
            ]"
        >

            <div
                :class="[
                    'flex items-center space-x-2 md:space-x-8',
                    {
                        'w-1/3': props.centerHeaderSlot
                    }
                ]"
            >
                <div
                    :class="{
                        'flex w-auto': forceSingleLineMobileLayout,
                        'flex-none w-24 md:flex md:w-auto': !forceSingleLineMobileLayout,
                    }"
                >
                    <h2 class="mt-0.5 mr-5 text-white text-sm md:text-base font-semibold drop-shadow tracking-wide select-none cursor-default">
                        {{ title }}
                    </h2>
                </div>
            </div>

            <div
                :class="[
                    'flex self-center',
                    {
                        'flex-grow': !props.centerHeaderSlot,
                        'md:w-2/3': props.centerHeaderSlot
                    }
                ]"
            >
                <div class="flex w-full space-x-4 md:space-x-8">
                    <div
                        :class="[
                            'flex items-start space-x-2',
                            {
                                'flex-grow': !props.centerHeaderSlot,
                                'w-1/2': props.centerHeaderSlot
                            }
                        ]"
                    >
                        <slot
                            name="header"
                            v-if="!collapsed"
                        />
                    </div>

                    <div
                        :class="[
                            'flex justify-end',
                            {
                                'flex-grow': !props.centerHeaderSlot,
                                'w-1/2': props.centerHeaderSlot
                            }
                        ]"
                    >

                        <button
                            v-if="collapsible"
                            @click="collapsed=!collapsed"
                            class="h-10 w-10 mt-0.5 md:h-7 md:w-7 p-1 grow-0 justify-self-end	items-center justify-center rounded-md text-white hover:bg-lifeworx-blue-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lifeworx-blue-700"
                        >
                            <span class="sr-only">Collapse</span>
                            <MinusIcon v-if="!collapsed" class="block h-5 w-5" aria-hidden="true" />
                            <QueueListIcon v-else class="block h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>

                </div>
            </div>

        </div>

        <div
            :class="[
                'bg-white',
                {
                    'hidden': collapsed
                }
            ]"
        >
            <slot />
        </div>
    </div>

</template>

