import { ref, computed } from 'vue'

import { defineStore, acceptHMRUpdate } from 'pinia'

export const useConfigStore = defineStore('config', () => {

    const config = ref({
        contact: {
            types: [],
            professions: []
        },
        care_need: {
            expert_factors: [],
            health_conditions: []
        },
        states: [],
        prospect_rates: {
            daily: [],
            hourly: []
        }
    })

    async function retrieveConfig() {
        return await new Promise((resolve, reject) => {
            axios.get(
                `/api/v1/config`
            ).then(response => {
                config.value = { ...config.value, ...response.data }
            }).catch((err) => {
                authStore.handleError(err)
                reject({ "error": err })
            })
        });
    }

    const HealthConditionDict = computed(() => {
        const dict = {}
        config.value.care_need.health_conditions.forEach(item => {
            dict[item.id] = item
        })
        return dict
    })

    const ExpertFactorDict = computed(() => {
        const dict = {}
        config.value.care_need.expert_factors.forEach(item => {
            dict[item.id] = item
        })
        return dict
    })

    return { config, retrieveConfig, HealthConditionDict, ExpertFactorDict }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot))
}
