import { ref, watch } from 'vue';

import { acceptHMRUpdate, defineStore } from 'pinia';
import { useNProgress } from '@vueuse/integrations/useNProgress';

import * as Sentry from "@sentry/vue";

import moment from 'moment';
import { patch } from '@/Helpers/takeLatestResponseRequest';
import { useAuthStore } from './auth';
import { useFormStatusStore } from './formStatus';
import { useStaffStore } from '@/Stores';
import { deepCopy, util } from '@/Helpers';

/*
    This store is the store dedicated for a single caregiver.
*/

const inactivityRefreshTime = 120000
export const useCaregiverSingleStore = defineStore('caregiverSingle', () => {

    const authStore = useAuthStore()
    const staffStore = useStaffStore()
    const formStatusStore = useFormStatusStore()

    const caregiver = ref(defaultCaregiver())

    const isEdit = ref(false)

    const lastUpdated = ref(null)

    const { isLoading } = useNProgress()
    const loading = ref(isLoading)

    const maxRetryDialogId = ref(null)
    function setMaxRetryDialogId(id) {
        maxRetryDialogId.value = id
    }
    function removeMaxRetryDialogId() {
        maxRetryDialogId.value = null
    }
    function getMaxRetryDialogId() {
        return maxRetryDialogId.value
    }

    const inactivityTimeout = ref(null);
    const resetInactivityRefreshTimer = () => {
        clearTimeout(inactivityTimeout.value)
        inactivityTimeout.value = setTimeout(refreshCaregiverData, inactivityRefreshTime); // 2 minutes
    }

    function stopInactivityTimeout() {
        clearTimeout(inactivityTimeout.value)
    }

    function defaultCaregiver() {
        return {
            first_name: null,
            last_name: null,
            profile_image_url: '',
            assigned_recruiter: staffStore.defaultStaff(),
            availability_pref_live_in: null,
            availability_pref_live_out: null,
            availability_pref_day_work: null,
            availability_pref_fill_ins_flexible: null,
            availability_pref_max_travel_time: null,
            availability_pref_min_hourly_rate: null,
            availability_pref_min_daily_rate: null,
            availability_pref_live_in_min_days_per_week: null,
            availability_pref_live_in_max_days_per_week: null,
            availability_pref_live_out_min_days_per_week: null,
            availability_pref_live_out_max_days_per_week: null,
            availability_pref_live_out_min_hours_per_day: null,
            availability_pref_live_out_max_hours_per_day: null,
            availability_notes: null,
            last_confirmed_date: null
        }
    }

    function refreshCaregiverData() {
        if (!caregiver.value.id) {
            return
        }

        getCaregiverById(caregiver.value.id)
            .then(response => {
                hydrate(response.data)
                resetInactivityRefreshTimer()
            })
    }

    watch([() => caregiver.value.id], (newValue, oldValue) => {
        resetInactivityRefreshTimer()
    }, {immediate: true})

    //
    // Hydration
    //

    async function hydrate(clientData) {
        caregiver.value = clientData
        lastUpdated.value = moment.utc()
    }

    function formatRequest(data) {
        if (data.assigned_recruiter) {
            data.assigned_recruiter = data.assigned_recruiter.id
            delete data.assigned_recruiter
        }
        return data
    }

    function applyLocalChangeToActiveCaregiver(change) {
        const noApplyFields = []
        for (const attr in change) {
            if (noApplyFields.indexOf(attr) == -1) {
                caregiver.value[attr] = change[attr]
            }
        }
    }

    async function getCaregiverByCoffeeUserProfileId(coffee_user_profile_id) {
        return await new Promise((resolve, reject) => {
            axios.get(
                `/api/v1/caregivers/coffee/${coffee_user_profile_id}`
            ).then(response => {
                resolve(response)
            }).catch((err) => {
                authStore.handleError(err)
                reject({ "error": err })
            })
        });
    }

    async function getCaregiverById(caregiver_id) {
        return await new Promise((resolve, reject) => {
            axios.get(
                `/api/v1/caregivers/${caregiver_id}`
            ).then(response => {
                resolve(response)
            }).catch((err) => {
                authStore.handleError(err)
                reject({ "error": err })
            })
        });
    }

    async function storeCaregiver(caregiverData, formId, referenceId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }
        return await new Promise((resolve, reject) => {
            const config = {
                headers: {
                    'X-Request-ID' : referenceId,
                }
            }
            axios.post('/api/v1/caregivers/', formatRequest(caregiverData), config)
                .then(function (response) {
                    if (response.status === 201) {
                        const redirectUrl = response.headers.location;
                        return axios.get(redirectUrl);
                    }
                }).then(function (response) {
                    resolve(response)
                }).catch(function (error) {
                    reject({ "error": error })
                    Sentry.captureException(error)
                }).finally(() => {
                    loading.value = false
                    if (formId) {
                        formStatusStore.removeLoading(formId)
                    }
                })
        });
    }

    async function updateCaregiver(caregiverId, modifiedClientData, formId, referenceId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        return await new Promise((resolve, reject) => {
            const config = {
                headers: {
                    'X-Request-ID' : referenceId,
                }
            }

            const caregiverCopy = deepCopy(caregiver.value)
            resolve({data: { ...caregiverCopy, ...modifiedClientData }})
            // reject({ "error": {
            //     response: {
            //         status: 400
            //     }
            // } })

            // patch(`/api/v1/caregivers/${caregiverId}`, formatRequest(modifiedClientData), config)
            //     .then(function (response) {
            //         resolve(response)
            //     }).catch(function (error) {
            //         reject({ "error": error })
            //         Sentry.captureException(error)
            //     }).finally(() => {
                    loading.value = false
                    if (formId) {
                        formStatusStore.removeLoading(formId)
                    }
                // })
        });
    }

    watch(caregiver, function(newValue, oldValue) {
        if (newValue.id == null) {
            return
        }

        let title = ''

        if (newValue.first_name) {
            title += ` ${newValue.first_name}`
        }

        if (newValue.last_name) {
            title += ` ${newValue.last_name}`
        }

        if (title == '') {
            title = `Unnamed Caregiver ${newValue.id} - LifeWorx Latte`
        } else {
            title = `${title} - Caregiver ${newValue.id} - LifeWorx Latte`
        }

        document.title = title

    }, { immediate: true, deep: true})

    return {
        defaultCaregiver,
        hydrate, getCaregiverByCoffeeUserProfileId, getCaregiverById,
        formatRequest, storeCaregiver, updateCaregiver,
        caregiver, isEdit, lastUpdated, loading,
        stopInactivityTimeout, resetInactivityRefreshTimer,
        applyLocalChangeToActiveCaregiver,
        getMaxRetryDialogId, setMaxRetryDialogId, removeMaxRetryDialogId,
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCaregiverSingleStore, import.meta.hot))
}
