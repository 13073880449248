<script setup>
    import LatteStaffLabel from '@/Components/LatteStaffLabel.vue'
    import { useStaffStore } from '@/Stores'

    const staffStore = useStaffStore()

    const props = defineProps({
        caregiver: {
            type: Object,
            required: true
        },
    })
</script>

<template>
    <div class="w-full p-2.5 overflow-clip">
        <div class="flex items-center">

            <img
                :src="caregiver.profile_image_url"
                alt="Profile Image"
                class="flex flex-shrink-0 h-14 w-14 rounded-full object-cover bg-stone-600 bg-opacity-5"
                loading="lazy"
            />

            <div class="ml-2">

                <h4 class="text-sm font-semibold text-lifeworx-blue-700">
                    {{ caregiver.first_name + ' ' + caregiver.last_name }}
                </h4>

                <div class="mt-1">
                    <LatteStaffLabel :staff="staffStore.getStaffById(caregiver.assigned_recruiter.id)" :actionable="false" />
                </div>

                <div class="mt-1 pb-1">
                    <img
                        :src="caregiver.job_rating_bar_path"
                        alt="Rating Bar"
                        class="h-4 w-36"
                        loading="lazy"
                    />
                </div>

            </div>

        </div>
    </div>
</template>
