<script setup>

    import { computed } from 'vue';

    const props = defineProps({
        modelValue: {
            required: true
        },
        value: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'text-lifeworx-blue-700'
        }
    })

    const emit = defineEmits(['update:modelValue']);

    const internalValue = computed({
        get: () => props.modelValue,
        set: (value) => emit('update:modelValue', value),
    });

</script>

<template>

    <label
        :for="`grid-stay-type-${props.value}`"
        :class="[
            'text-sm font-semibold group whitespace-nowrap',
            {
                'opacity-50': props.disabled,
                'cursor-pointer': !props.disabled
            },
            color
        ]"
    >
        <input
            :id="`grid-stay-type-${props.value}`"
            type="radio"
            v-model="internalValue"
            :value="props.value"
            :disabled="props.disabled"
            :class="[
                'mr-1 h-5 w-5 text-lifeworx-blue-600 checked:ring-lifeworx-blue-600 focus:ring-lifeworx-blue-600 ',
                {
                    'opacity-50 border-stone-500': props.disabled,
                    'cursor-pointer border-lifeworx-blue-600': !props.disabled
                }
            ]"
        />
        {{ props.label }}
    </label>

</template>
