<script setup>
    import { computed, onMounted, ref, watch } from 'vue'

    import CaregiverAutocomplete from './CaregiverAutocomplete.vue'
    import LatteStaffLabel from '@/Components/LatteStaffLabel.vue'
    import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/vue/20/solid'
    import { useCaregiverStore, useStaffStore } from '@/Stores'
    import { deepCopy } from '@/Helpers'

    const props = defineProps({
        modelValue: {
            type: [Object, null],
            required: true,
        },
    })

    const caregiverStore = useCaregiverStore()
    const staffStore = useStaffStore()

    const emit = defineEmits(['update:modelValue'])

    const unassignedCaregiver = ref({
        id: null,
        first_name: null,
        last_name: null,
    })

    const selectedCaregiver = ref({})

    function updateCaregiver(newValue) {
        selectedCaregiver.value = newValue
        emit('update:modelValue', newValue)
    }

    function clearSelectedCaregiver() {
        selectedCaregiver.value = deepCopy(unassignedCaregiver.value)
        emit('update:modelValue', selectedCaregiver.value)
    }

    const caregiverName = computed(() => {
        const { first_name, last_name } = selectedCaregiver.value || {};

        if (!first_name && !last_name) {
            return undefined;
        }

        return [first_name, last_name].filter(Boolean).join(' ');
    })

    watch(() => props.modelValue, async (newValue) => {
        const caregiverFull = newValue?.id ? await caregiverStore.findCaregiverById(newValue.id) : null
        selectedCaregiver.value = caregiverFull ?? deepCopy(unassignedCaregiver.value)
    }, {immediate: true, deep: true})

    onMounted(async () => {
        if (props.modelValue.id) {
            const caregiverFull = await caregiverStore.findCaregiverById(props.modelValue.id)
            selectedCaregiver.value = caregiverFull ?? deepCopy(unassignedCaregiver.value)
        } else {
            selectedCaregiver.value = deepCopy(unassignedCaregiver.value)
        }
    })

</script>


<template>

    <div>
        <CaregiverAutocomplete
            id="grid-assigned-caregiver"
            class="w-full text-stone-700 focus:outline-none"
            v-model="selectedCaregiver"
            @update:modelValue="updateCaregiver"
        />

        <div class="w-full p-2 h-20 bg-stone-200 relative rounded-b-lg border border-stone-300">
            <div class="flex items-center h-full">

                <div v-if="selectedCaregiver.id" class="flex items-center h-full">
                    <button
                        v-if="selectedCaregiver.id"
                        @click="clearSelectedCaregiver"
                        class="absolute top-2 right-2 text-stone-500 hover:text-stone-700 focus:outline-none"
                        aria-label="Clear Selected Caregiver"
                    >
                        <XMarkIcon class="h-4 w-4" />
                    </button>

                    <img
                        :src="selectedCaregiver.profile_image_url"
                        alt="Profile Image"
                        class="h-10 w-10 rounded-full object-cover ring-2 ring-lifeworx-blue-500"
                    />

                    <div class="ml-4">
                        <h4 class="text-sm font-semibold text-lifeworx-blue-700">
                            {{ caregiverName }}
                        </h4>

                        <div class="mt-1">
                            <LatteStaffLabel :staff="staffStore.getStaffById(selectedCaregiver.assigned_recruiter.id)" :actionable="false" size="xs" />
                        </div>

                        <div class="mt-1 pb-1">
                            <img
                                :src="selectedCaregiver.job_rating_bar_path"
                                alt="Rating Bar"
                                class="h-4"
                            />
                        </div>
                    </div>

                </div>

                <div v-else class="flex items-center h-full">
                    <ExclamationCircleIcon class="h-7 w-7 text-stone-400" />
                    <h4 class="text-sm text-stone-400 ml-2 italic">
                        Unassigned
                    </h4>
                </div>

            </div>
        </div>
    </div>


</template>
