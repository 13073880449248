<script setup>

    import Modal from '@/Components/App/Modal.vue'
    import PrimaryButton from '@/Components/Forms/PrimaryButton.vue';
    import SecondaryButton from '@/Components/Forms/SecondaryButton.vue';
    import DatePicker from '@/Components/Forms/DatePicker.vue';
    import { ref, watch } from 'vue';
    import RadioInput from './Forms/RadioInput.vue';
    import { util } from '@/Helpers';

    const emit = defineEmits(['range:save', 'range:cancel'])

    const start_date = ref(null)
    const end_date = ref(null)
    const untilMode = ref('indefinitely')

    const props = defineProps({
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        notice: {
            type: String,
            default: ''
        },
        start_date: {
            type: [String, null],
            required: true
        },
        button_text: {
            type: String,
            default: ''
        },
        button_style: {
            type: String,
            default: undefined
        }
    })

    function handleConfirm() {
        emit('range:save', start_date.value, untilMode.value == 'indefinitely' ? null : end_date.value)
        untilMode.value = 'indefinitely'
        end_date.value = null
    }

    function handleCancel() {
        end_date.value = undefined
        untilMode.value = 'indefinitely'
        emit('range:cancel')
    }

    watch(() => props.start_date, (newValue) => {
        start_date.value = newValue
        end_date.value = util.date.nextSunday(newValue)
    }, {immediate : true})

</script>

<template>

    <Modal :show="props.start_date != null">

        <div class="p-5">
            <h2
                v-if="props.title != ''"
                class="mb-4 font-bold text-xl text-lifeworx-blue-600"
            >
                {{ props.title }}
            </h2>

            <div class="flex flex-col gap-4 mt-4 mb-10 ">
                <div
                    class="text-lifeworx-blue-700 text-sm font-semibold"
                    v-if="props.description != ''" v-html="props.description"
                >
                </div>

                <div v-if="props.notice != ''">
                    <span class="font-bold text-lifeworx-blue-700 text-sm">Note: </span>
                    <span class="text-lifeworx-blue-700 text-sm font-semibold">{{ props.notice }}</span>
                </div>

                <div class="text-lifeworx-blue-700 text-sm font-semibold">
                    Temp shifts within the designated range will be updated without changing the end date.
                </div>
            </div>

            <div class="flex gap-5">
                <div class="w-full">
                    <span class="inline-block font-semibold mb-2 text-stone-500 text-sm">Starting</span>
                    <div class="grid place-content-around bg-lifeworx-blue-500 bg-opacity-10 justify-con min-h-40 rounded border border-sky-200">
                        <DatePicker
                            :min-date="props.start_date"
                            v-model="start_date"
                            :teleport="true"
                            :clearable="false"
                        />
                    </div>
                </div>
                <div class="w-full">
                    <span class="inline-block font-semibold mb-2 text-stone-500 text-sm">Until</span>
                    <div
                        :class="[
                            'rounded border min-h-40 border-lifeworx-blue-600 border-opacity-20 flex flex-col',
                            {
                                'bg-lifeworx-blue-500 bg-opacity-10 ': untilMode == 'indefinitely',
                                'bg-white': untilMode == 'date'
                            }
                        ]"
                    >
                        <div class="p-4 pl-6 border-b border-sky-200">
                            <RadioInput
                                value="indefinitely"
                                label="Indefinitely"
                                v-model="untilMode"
                            />
                        </div>
                        <div
                            :class="[
                                'grid place-content-around flex-grow ',
                                {
                                    'bg-lifeworx-blue-500 bg-opacity-10 ': untilMode == 'date',
                                    'bg-white': untilMode == 'indefinitely'
                                }
                            ]"
                        >
                            <div class="flex items-center space-x-2">
                                <RadioInput
                                    value="date"
                                    label=""
                                    v-model="untilMode"
                                />
                                <DatePicker
                                    :min-date="start_date"
                                    v-model="end_date"
                                    :teleport="true"
                                    :clearable="false"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="mt-5 flex justify-end border-t pt-5">
            <SecondaryButton
                @click="handleCancel"
            >
                Cancel
            </SecondaryButton>

            <PrimaryButton
                class="ml-3"
                :button_style="button_style"
                @click="handleConfirm"
            >
                {{ button_text }}
            </PrimaryButton>
        </div>
    </Modal>

</template>
