<script setup>

    import { computed } from 'vue';
    import { util } from '@/Helpers';

    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'

    import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
    import moment from 'moment';

    const props = defineProps({
        modelValue: {
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    })

    const handleNextWeek = () => {
        if (!props.disabled) {
            emit('update:modelValue', util.date.nextDate(props.modelValue, 7))
        }
    }

    const handlePreviousWeek = () => {
        if (!props.disabled) {
            emit('update:modelValue', util.date.nextDate(props.modelValue, -7))
        }
    }

    const summary = computed(() => {
        return util.date.getWeekText(props.modelValue)
    })

    const emit = defineEmits(['update:modelValue']);

    const internalValue = computed({
        get: () => moment(props.modelValue).toDate(),
        set: (value) => emit('update:modelValue', util.date.toDateString(value[0])),
    });

</script>

<template>

    <div
        :class="[
            'flex justify-between rounded w-80 h-7',
            {
                'bg-white': !props.disabled,
                'bg-stone-200 text-stone-400': props.disabled
            }
        ]"
    >
            <div
                :class="[
                    'border-r pt-0.5 px-2 rounded-l overflow-clip ',
                    {
                        'cursor-pointer hover:bg-stone-200 hover:text-lifeworx-blue-900': !props.disabled,
                        'bg-stone-100 text-stone-400': props.disabled
                    }
                ]"
                @click="handlePreviousWeek"
            >
                <ChevronLeftIcon class="block h-6 w-6" aria-hidden="true" />
            </div>

            <VueDatePicker
                v-model="internalValue"
                week-picker
                hide-input-icon
                :disabled="props.disabled"
                :enable-time-picker="false"
                week-start="0"
                :year-range="[2018, moment().add(2, 'years').year()]"
                :min-date="moment('2018-09-03', 'YYYY-MM-DD').toDate()"
                :max-date="moment().add(2, 'years').toDate()"
                prevent-min-max-navigation
                format="yyyy-MM-dd"
                focus-start-date
                :start-date="internalValue"
                :autoApply="true"
                :action-row="{ showSelect: true, showCancel: true, showPreview: false }"
            >
                <template #trigger>
                    <div
                        :class="[
                            'flex text-center justify-center h-7',
                            {
                                'cursor-pointer hover:bg-stone-200 hover:text-lifeworx-blue-900': !props.disabled,
                                'cursor-default bg-stone-100 text-stone-400': props.disabled
                            }
                        ]">
                            <span class="flex font-semibold mt-1 text-sm">{{ summary }}</span><ChevronDownIcon class="flex mt-0.5 h-6 w-6 pr-1" aria-hidden="true" />
                        </div>
                </template>
            </VueDatePicker>

            <div
                :class="[
                    'border-l pt-0.5 px-2 rounded-r overflow-clip',
                    {
                        'cursor-pointer hover:bg-stone-200 hover:text-lifeworx-blue-900': !props.disabled,
                        'bg-stone-100 opacity-75 text-stone-400': props.disabled
                    }
                ]"
                @click="handleNextWeek"
            >
                <ChevronRightIcon class="block h-6 w-6" aria-hidden="true" />
            </div>

    </div>

</template>
