<script setup>
    import { computed } from 'vue';

    import { util } from '@/Helpers';
    import moment from 'moment';
    import ClientCaregiverScheduleViewBlock from '@/Components/Caregiver/ClientCaregiverScheduleViewBlock.vue';

    const props = defineProps({
        schedule_start_date: {
            type: String,
            required: true
        },
        block: {
            type: Object,
            required: true
        }
    })

    const isCurrentOrFutureWeek = computed(() => {
        const lastSunday = moment(util.date.lastSunday(), util.date.date_format)
        const startDate = moment(props.schedule_start_date, util.date.date_format)

        return startDate.isSameOrAfter(lastSunday);
    })

    function popperTheme() {
        if (isCurrentOrFutureWeek.value) {
            return 'lifeworx-schedule-blue'
        } else {
            return 'lifeworx-schedule-gray'
        }
    }
</script>


<template>
    <li
        class="relative flex group justify-center"
        :class="`col-start-${util.date.dateDiff(schedule_start_date, block.start_date) + 1}`"
        :style="`grid-row: ${util.schedule.start(block.start_time)} / span ${util.schedule.span(block.duration)}`"
    >
        <VDropdown
            :triggers="['hover']"
            :theme="popperTheme()"
            no-auto-focus
            placement="top"
            :disabled="['lg', 'xl', '2xl'].includes(util.schedule.sizeOf(block)) || (!isCurrentOrFutureWeek && !['2xs', 'xs', 'sm'].includes(util.schedule.sizeOf(block)))"
        >
            <ClientCaregiverScheduleViewBlock
                :isCurrentOrFutureWeek="isCurrentOrFutureWeek"
                :block="block"
                :size="util.schedule.sizeOf(block)"
                class="group absolute flex inset-0.5 flex-col overflow-y-auto text-xs whitespace-nowrap cursor-default opacity-70 border-opacity-50 bg-opacity-[0.1]"
                :class="[{
                    'rounded-t-lg': block.continues,
                    'rounded-b-lg': block.continuation,
                    'rounded-lg': !block.continues && !block.continuation,
                    'p-0.5 border-2 ml-0.5 mr-2': ['2xs', 'xs'].includes(util.schedule.sizeOf(block)),
                    'p-1 border-2 ml-0.5 mr-2': ['sm', 'md', 'lg', 'xl', '2xl'].includes(util.schedule.sizeOf(block)),
                    'z-40 hover:z-50 group-even:mr-8 group-odd:ml-8 group-odd:mr-0.5 backdrop-brightness-200 backdrop-saturate-0 backdrop-blur-sm': block.overlaps,
                    'backdrop-blur-sm bg-opacity-[0.2]':  block.id && block.overlaps,
                    'border-dashed': !block.id && isCurrentOrFutureWeek,
                    'bg-opacity-[0.2] border-lifeworx-blue-900 bg-lifeworx-blue-900 text-lifeworx-blue-900 p-2': !isCurrentOrFutureWeek,
                    'bg-opacity-[0.2] bg-lifeworx-blue-600 border-lifeworx-blue-600 text-lifeworx-blue-600': isCurrentOrFutureWeek,
                }]"
                @click="() => toggleSelect(block)"
            />
            <template #popper>
                <ClientCaregiverScheduleViewBlock
                    class="px-3 pt-0 pb-8"
                    :isCurrentOrFutureWeek="isCurrentOrFutureWeek"
                    :block="block"
                    :size="'2xl'"
                />
            </template>
        </VDropdown>
    </li>
</template>
