<script setup>
    import { computed } from 'vue';

    import { util } from '@/Helpers';
    import moment from 'moment';
    import LiveInScheduleViewBlock from '@/Components/Caregiver/LiveOutScheduleViewBlock.vue';

    const props = defineProps({
        schedule_start_date: {
            type: String,
            required: true
        },
        block: {
            type: Object,
            required: true
        }
    })

    const isCurrentOrFutureWeek = computed(() => {
        const lastSunday = moment(util.date.lastSunday(), util.date.date_format)
        const startDate = moment(props.schedule_start_date, util.date.date_format)

        return startDate.isSameOrAfter(lastSunday);
    })
</script>


<template>
    <li
        class="relative flex group justify-center"
        :class="`col-start-${util.date.dateDiff(schedule_start_date, block.start_date) + 1}`"
        :style="`grid-row: ${util.schedule.start(block.start_time)} / span ${util.schedule.span(block.duration)}`"
    >
        <LiveInScheduleViewBlock
            :isCurrentOrFutureWeek="isCurrentOrFutureWeek"
            :block="block"
            :size="util.schedule.sizeOf(block)"
            class="group absolute flex inset-0.5 flex-col overflow-y-auto text-xs whitespace-nowrap cursor-default opacity-70 border-opacity-50 bg-opacity-[0.1]"
            :class="[{
                'rounded-t-lg': block.continues,
                'rounded-b-lg': block.continuation,
                'rounded-lg': !block.continues && !block.continuation,
                'p-0.5 border-2 ml-0.5 mr-2': ['2xs', 'xs'].includes(util.schedule.sizeOf(block)),
                'p-1 border-2 ml-0.5 mr-2': ['sm', 'md', 'lg', 'xl', '2xl'].includes(util.schedule.sizeOf(block)),
                'z-40 hover:z-50 group-even:mr-8 group-odd:ml-8 group-odd:mr-0.5 backdrop-brightness-200 backdrop-saturate-0 backdrop-blur-sm': block.overlaps,
                'backdrop-blur-sm bg-opacity-[0.2]':  block.id && block.overlaps,
                'border-dashed': !block.id && isCurrentOrFutureWeek,
                'bg-opacity-[0.2] bg-lifeworx-green-600 border-lifeworx-green-600 text-lifeworx-green-600': isCurrentOrFutureWeek,
            }]"
            @click="() => toggleSelect(block)"
        />
    </li>
</template>
