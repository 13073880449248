<script setup>

    import { useSlots } from 'vue'
    const slots = useSlots()

    const props = defineProps({
        value: {
            type: String,
        },
        palette: {
            type: String,
            default: 'gray'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    })

</script>

<template>
    <label
        :class="[
            'block font-medium text-sm group',
            {
                'cursor-pointer': slots.default,
                'text-stone-500': props.palette == 'gray',
                'text-lifeworx-blue-800': props.palette == 'blue',
                'font-semibold': props.palette == 'blue',
                'opacity-50': props.disabled
            }
        ]"
    >
        <span
            v-if="props.value"
            class="block mb-1"
        >
            {{ props.value }}
        </span>
        <slot />
    </label>
</template>
