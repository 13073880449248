<script setup>

    import { util } from '@/Helpers'
    import { ref, computed, watch } from 'vue'

    const container = ref(null)
    const containerNav = ref(null)
    const containerOffset = ref(null)

    const props = defineProps({
        start_date: {
            type: String,
            required: true
        }
    })

    const next7Dates = computed(() => {
        return util.date.generateNext7MomentDays(props.start_date);
    })

    const timeEntries = ['12AM']
    for (let i = 1; i < 12; i++) {
        timeEntries.push(`${i}AM`)
    }
    timeEntries.push('12PM')
    for (let i = 1; i < 12; i++) {
        timeEntries.push(`${i}PM`)
    }

    watch(() => props.selected_shift_ids, (newValue) => {
        selectedIds.value = [...newValue]
    })

</script>

<template>
    <div class="border-t flex h-full flex-col">
        <div ref="container" class="isolate flex flex-auto flex-col overflow-auto bg-white">
            <div style="width: 165%" class="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full mb-1 border-b border-b-stone-100">
                <div ref="containerNav" class="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-6">

                    <div class="-mr-px hidden grid-cols-7 divide-x divide-stone-100 border-r border-stone-100 text-xs leading-6 text-stone-500 sm:grid">
                        <div class="col-end-1 w-12" />
                        <div v-for="date in next7Dates" class="flex items-center justify-center py-2">
                            <span>{{ date.format('dddd') }} <span class="items-center justify-center font-semibold text-stone-900"> {{ date.format('MM/DD') }}</span></span>
                        </div>
                    </div>
                </div>

                <div class="flex flex-auto">
                    <div class="sticky left-0 z-10 w-12 flex-none bg-white ring-1 ring-stone-100" />
                    <div class="grid flex-auto grid-cols-1 grid-rows-1">

                        <!-- Horizontal lines -->
                        <div
                            class="col-start-1 col-end-2 row-start-1 grid divide-y divide-stone-100"
                            style="grid-template-rows: repeat(24, minmax(1.15rem, 1fr))"
                        >
                            <div ref="containerOffset" class="row-end-1 h-1.5" />
                            <template v-for="time in timeEntries">
                                <div>
                                    <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-12 pr-2 text-right text-3xs leading-5 text-stone-400">{{ time }}</div>
                                </div>
                            </template>
                        </div>

                        <!-- Vertical lines -->
                        <div class="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-stone-100 sm:grid sm:grid-cols-7">
                            <div class="col-start-1 row-span-full" />
                            <div class="col-start-2 row-span-full" />
                            <div class="col-start-3 row-span-full" />
                            <div class="col-start-4 row-span-full" />
                            <div class="col-start-5 row-span-full" />
                            <div class="col-start-6 row-span-full" />
                            <div class="col-start-7 row-span-full" />
                            <div class="col-start-8 row-span-full w-6" />
                        </div>

                        <!-- Events -->
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
