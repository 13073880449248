<script setup>

    import { computed } from 'vue'
    import VueDatePicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'
    import { CalendarDateRangeIcon, CalendarDaysIcon } from '@heroicons/vue/24/outline'
    import { util } from '@/Helpers'
    import moment from 'moment'

    const props = defineProps({
        startDate: {
            type: [String, null]
        },
        endDate: {
            type: [String, null],
        },
        durationType: {
            type: [String, null],
            default: 'ongoing'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        teleport: {
            type: [String, Boolean],
            default: false
        }
    })

    const emit = defineEmits(['update:startTime', 'update:duration', 'update:startDate', 'update:endDate'])

    const internalValue = computed({
        get: () => {
            if (props.durationType == 'temp') {
                return [ moment(props.startDate).toDate(), moment(props.endDate).toDate() ]
            } else {
                return moment(props.startDate).toDate()
            }
        },
        set: (value) => {
            if (props.durationType == 'temp') {
                emit('update:startDate', util.date.toDateString(value[0]))
                emit('update:endDate', util.date.toDateString(value[1]))
            } else {
                emit('update:startDate', util.date.toDateString(value))
                emit('update:endDate', null)
            }
        }
    })

</script>

<template>
    <div>
        <VueDatePicker
            v-if="props.durationType == 'temp'"
            v-model="internalValue"
            :enable-time-picker="false"
            :clearable="false"
            :range="{
                showLastInRange: false,
            }"
            :disabled="props.disabled"
            :min-date="moment(util.date.lastSunday()).toDate()"
            week-start="0"
            :autoApply="true"
            :teleport="props.teleport"
            format="M/dd/yy"
            :ui="{ input: 'font-semibold rounded-lg text-center border-stone-400 focus:border focus:border-lifeworx-blue-600 focus:ring-0 focus:outline focus:outline-3' }"
        >
            <template #input-icon>
                <CalendarDateRangeIcon class="inline-flex mx-2 h-5 w-5 -mt-0.5 text-stone-500 input-slot-image" />
            </template>
        </VueDatePicker>

        <VueDatePicker
            v-else
            v-model="internalValue"
            :enable-time-picker="false"
            :clearable="false"
            :disabled="props.disabled"
            :min-date="moment(util.date.lastSunday()).toDate()"
            week-start="0"
            :autoApply="true"
            :teleport="props.teleport"
            prevent-min-max-navigation
            format="M/dd/yyyy"
            :ui="{ input: 'font-semibold rounded-lg text-center border-stone-400 focus:border focus:border-lifeworx-blue-600 focus:ring-0 focus:outline focus:outline-3' }"
        >
            <template #input-icon>
                <CalendarDaysIcon class="inline-flex mx-2 h-5 w-5 -mt-0.5 text-stone-500 input-slot-image" />
            </template>
        </VueDatePicker>
    </div>
</template>
