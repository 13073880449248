<script setup>

    import { util } from '@/Helpers';
    import { useClientInvoiceShiftStore, useClientShiftStore } from '@/Stores';
    import { ClipboardDocumentListIcon } from '@heroicons/vue/20/solid'
    import { computed, watch } from 'vue';
    import { useNotificationStore } from '@/Stores'

    const emit = defineEmits(['shifts:copy'])

    const clientShiftStore = useClientShiftStore()
    const clientInvoiceShiftStore = useClientInvoiceShiftStore()
    const notificationStore = useNotificationStore()


    const props = defineProps({
        selected_shift_ids: {
            type: Array,
            required: true
        },
        start_date: {
            type: String,
            required: true
        },
        client: {
            type: [Object, null],
            required: true
        },
        planned_shifts: {
            type: Array,
            required: false,
            default: []
        },
    })

    let clientShifts
    let contextHint
    let canCopy

    watch([() => props.client, () => props.start_date], ([client, start_date]) => {
        if (start_date >= util.date.lastSunday()) {
            clientShifts = clientShiftStore.computedClientShift(client?.id)
        } else {
            clientShifts = clientInvoiceShiftStore.computedClientShift(client?.id)
        }

        contextHint = computed(() => {
            if (props.start_date < util.date.lastSunday()) {
                return getContextHintForOldWeek()
            }

            if (props.planned_shifts.length == 0 && props.selected_shift_ids.length == 0) {
                if (clientShifts.value.length == 0) {
                    return getNoDataContextHint()
                }

                return getUnassignedCareContextHint()
            }

            if (props.selected_shift_ids.length) {
                return getEditShiftContextHint()
            }

            if (props.planned_shifts.length) {
                return getAddShiftContextHint();
            }

            return ''
        })

        canCopy = computed(() => {
            if (clientShifts && clientShifts.value.length == 0) { return false; }
            return true;
        })
    }, {immediate: true})

    function getContextHintForOldWeek() {
        let message = `<span class="font-semibold text-lifeworx-blue-900 ">Historical Invoicing Data: </span>`
        let duration = 0
        let caregivers = new Set()

        const nextSunday = util.date.nextSunday(props.start_date)

        clientShifts.value.forEach(shift => {
            if (shift.start_date >= props.start_date && shift.start_date < nextSunday) {
                duration += shift.duration
                if (shift.assigned_caregiver.id) {
                    caregivers.add(shift.assigned_caregiver.id)
                }
            }
        })
        duration = util.date.durationToHours(duration)

        if (!duration) {
            return ''
        }

        const hourText = `${duration} ${duration > 1 ? 'hours' : 'hour'}`
        const caregiverText = `${caregivers.size} ${caregivers.size > 1 ? 'caregivers' : 'caregiver'}`

        return `${message} <span>${hourText} worked by ${caregiverText}</span>`
    }

    function getNoDataContextHint() {
        return `<span class="font-semibold text-lifeworx-blue-900 ">Tip: </span> <span class=" text-lifeworx-blue-700">Add shifts with rate information for revenue estimates</span>`
    }

    function getUnassignedCareContextHint() {
        let durationWeek = 0
        let durationMonth = 0
        const endOfNexMonthPeriodDate = util.date.nextDate(props.start_date, 7 * 5 - 1)
        const nextSunday = util.date.nextSunday(props.start_date)
        const saturdayNext2Weeks = util.date.nextDate(nextSunday, 6)
        const sundayNext2Weeks = util.date.nextDate(nextSunday, 7)
        clientShifts.value.forEach(shift => {
            if (!shift.assigned_caregiver.id) {
                const startDate = util.getLargerItem(props.start_date, shift.start_date)

                if (shift.end_date == null) {
                    durationWeek +=  util.schedule.getShiftDurationWithinRage(shift.day_of_week, startDate, saturdayNext2Weeks, shift.duration)
                    durationMonth += util.schedule.getShiftDurationWithinRage(shift.day_of_week, startDate, endOfNexMonthPeriodDate, shift.duration)
                } else {
                    const dayAfterShiftEndDate = util.date.nextDate(shift.end_date, 1)
                    if (shift.start_date < sundayNext2Weeks && shift.end_date >= nextSunday) {
                        durationWeek +=  util.schedule.getShiftDurationWithinRage(shift.day_of_week, startDate, util.getSmallerItem(dayAfterShiftEndDate, saturdayNext2Weeks), shift.duration)
                    }
                    if (shift.start_date < endOfNexMonthPeriodDate && shift.end_date >= nextSunday) {
                        durationMonth +=  util.schedule.getShiftDurationWithinRage(shift.day_of_week, startDate, util.getSmallerItem(dayAfterShiftEndDate, endOfNexMonthPeriodDate), shift.duration)
                    }
                }
            }
        })

        durationWeek = util.date.durationToHours(durationWeek)
        const hourWeekText = `${durationWeek} ${durationWeek > 1 || durationWeek == 0 ? 'hours' : 'hour'}`

        durationMonth = util.date.durationToHours(durationMonth)
        const hourMonthText = `${durationMonth} ${durationMonth > 1 || durationMonth == 0 ? 'hours' : 'hour'}`

        if (durationWeek == 0 && durationMonth == 0) {
            return getNoDataContextHint();
        }

        return `<span class="font-semibold text-lifeworx-blue-900 ">Unfilled Need: </span><span class="font-semibold text-lifeworx-red-500">${hourWeekText}</span><span> of unassigned care next week, </span><span class="font-semibold text-lifeworx-red-500">${hourMonthText}</span><span> in the next month</span>`
    }

    function getEditShiftContextHint() {
        if (props.selected_shift_ids.length == 0) {
            return ''
        }

        const shiftTypeText = `${props.selected_shift_ids.length} ${props.selected_shift_ids.length > 1 ? 'shifts' : 'shift'}`
        const title = props.selected_shift_ids.length > 1 ? 'Edit Shifts' : 'Edit Shift'
        return `<span class="font-semibold text-lifeworx-blue-900">${title}: </span><span>selected <span class="text-lifeworx-green-500 font-semibold">${shiftTypeText}</span></span>`
    }

    function getAddShiftContextHint() {
        function getRangeText(min, max) {
            let final = ''
            if (min) {
                final += util.currencyWithoutFraction(min)
            }

            if (max) {
                if (final !== '') {
                    final += ` - ${util.currencyWithoutFraction(max)}`
                }
            }
            return final
        }

        if (props.planned_shifts.length == 0) {
            return ''
        }

        const firstShift = props.planned_shifts[0]
        const shiftLength = props.planned_shifts.length
        let contextHintText = ''

        if (props.client.type == 'prospect') {
            let minRevenueRange = 0
            let maxRevenueRange = 0
            if (firstShift.end_date == null) {
                const totalDuration = props.planned_shifts.length * firstShift.duration
                const totalHourDurations = util.date.durationToHours(totalDuration)
                if (firstShift.charge_duration_rate_type == 'daily') {
                    minRevenueRange += 2 * shiftLength * firstShift.min_charge_rate_amount
                    maxRevenueRange += 2 * shiftLength * firstShift.max_charge_rate_amount
                } else {
                    minRevenueRange += 2 * totalHourDurations * firstShift.min_charge_rate_amount
                    maxRevenueRange += 2 * totalHourDurations * firstShift.max_charge_rate_amount
                }

                const rangeRevenueText = getRangeText(minRevenueRange, maxRevenueRange)
                contextHintText = `<span class="font-semibold text-lifeworx-green-500">${totalHourDurations} ${totalHourDurations > 1 ? 'hours' : 'hour'}</span> per week`
                if (rangeRevenueText !== '') {
                    contextHintText += ` for estimate of <span class="font-semibold text-lifeworx-green-500">${ rangeRevenueText }</span> new revenue per cycle`
                }
            } else {
                let totalDuration = 0
                props.planned_shifts.forEach(shift => {
                    const occurrence = (util.date.dateDiff(shift.start_date, shift.end_date) / 7) + 1
                    const hourDurations = util.date.durationToHours(shift.duration)
                    totalDuration += occurrence * shift.duration
                    if (shift.charge_duration_rate_type == 'daily') {
                        minRevenueRange += occurrence * shift.min_charge_rate_amount
                        maxRevenueRange += occurrence * shift.max_charge_rate_amount
                    } else {
                        minRevenueRange += occurrence * hourDurations * shift.min_charge_rate_amount
                        maxRevenueRange += occurrence * hourDurations * shift.max_charge_rate_amount
                    }
                })
                const totalHourDurations = util.date.durationToHours(totalDuration)
                const rangeRevenueText = getRangeText(minRevenueRange, maxRevenueRange)
                contextHintText = `<span class="font-semibold text-lifeworx-green-500">${totalHourDurations} total ${totalHourDurations > 1 ? 'hours' : 'hour'}</span>`
                if (rangeRevenueText !== '') {
                    contextHintText += ` for an estimate of <span class="font-semibold text-lifeworx-green-500">${util.currencyWithoutFraction(minRevenueRange)} - ${util.currencyWithoutFraction(maxRevenueRange)}</span> in total`
                }
            }
        } else {
            if (firstShift.end_date == null) {
                let revenue = 0
                let totalDuration = 0
                props.planned_shifts.forEach(shift => {
                    totalDuration += shift.duration
                    if (shift.charge_duration_rate_type == 'daily') {
                        revenue += 2 * shiftLength * shift.charge_rate_amount
                    } else {
                        const hourDurations = util.date.durationToHours(shift.duration)
                        revenue += 2 * hourDurations * shift.charge_rate_amount
                    }
                })
                const totalHourDurations = util.date.durationToHours(totalDuration)
                contextHintText = `<span class="font-semibold text-lifeworx-green-500">${totalHourDurations} ${totalHourDurations > 1 ? 'hours' : 'hour'}</span> per week`
                if (revenue) {
                    contextHintText += ` for estimate of <span class="font-semibold text-lifeworx-green-500">${util.currencyWithoutFraction(revenue)}</span> new revenue per cycle`
                }
            } else {
                let revenue = 0
                let totalDuration = 0
                props.planned_shifts.forEach(shift => {
                    totalDuration += shift.duration
                    const occurrence = (util.date.dateDiff(firstShift.start_date, firstShift.end_date) / 7) + 1
                    const hourDurations = util.date.durationToHours(shift.duration)

                    if (shift.charge_duration_rate_type == 'daily') {
                        revenue += occurrence * shift.charge_rate_amount
                    } else {
                        revenue += occurrence * hourDurations * shift.charge_rate_amount
                    }
                })
                const totalHourDurations = util.date.durationToHours(totalDuration)
                contextHintText = `<span class="font-semibold text-lifeworx-green-500">${totalHourDurations} total ${totalHourDurations > 1 ? 'hours' : 'hour'}</span>`
                if (revenue) {
                    contextHintText += ` for an estimate of <span class="font-semibold text-lifeworx-green-500">${util.currencyWithoutFraction(revenue)}</span> in total`
                }
            }
        }

        return `<span class="font-semibold text-lifeworx-blue-900">Adding ${shiftLength > 1 ? 'shifts' : 'shift'}: </span>${contextHintText}`
    }

    function handleSaveShiftViewToClipboard() {
        emit('shifts:copy')
        notificationStore.add('Shift Summary Copied to Clipboard')
    }

</script>

<template>
    <div class="flex border-t py-4 px-6 h-14">
        <div
            class="flex-auto pt-0.5 text-sm"
            v-html="contextHint"
        />
        <div class="flex-none -mt-1 p-1 grid justify-center">
            <VTooltip
                :disabled="!canCopy"
                :triggers="['hover']"
                :autoHide="true"
            >
                <button
                    @click.stop="handleSaveShiftViewToClipboard"
                    :disabled="!canCopy"
                    :class="[
                        '-mt-1 p-1 grid justify-center',
                        {
                            'text-stone-300 disabled:text-stone-300 cursor-default': !canCopy,
                            'text-lifeworx-blue-600 hover:text-lifeworx-blue-500 cursor-pointer': canCopy,
                        }
                    ]"
                >
                    <ClipboardDocumentListIcon class="w-6 h-6" />
                </button>
                <template #popper>
                    <span class="text-sm">
                        Copy Shift Summary
                    </span>
                </template>
            </VTooltip>
        </div>

    </div>
</template>
