<script setup>

    import { computed, ref, watch } from 'vue'
    import { deepCopy, getModifiedProperties, util } from '@/Helpers';
    import LwxSelect from '@/Components/Forms/LwxSelect.vue';
    import InputLabel from '@/Components/Forms/InputLabel.vue';
    import Checkbox from '@/Components/Forms/Checkbox.vue';
    import TimeRangePicker from '@/Components/Forms/TimeRangePicker.vue';
    import WeekDaySelect from '@/Components/Forms/WeekDaySelect.vue';
    import '@vuepic/vue-datepicker/dist/main.css'
    import { IconCalendarAdd28Regular } from '@iconify-prerendered/vue-fluent'
    import AreaLabel from '@/Components/AreaLabel.vue';
    import { useConfigStore } from '@/Stores';
    import { storeToRefs } from 'pinia';
    import TextInput from '@/Components/Forms/TextInput.vue';
    import RangeSlider from '@/Components/Forms/RangeSlider.vue';

    const emit = defineEmits(['schedule-data:change', ['preference-data:change']])

    const configStore = useConfigStore()
    const { config } = storeToRefs(configStore)

    const props = defineProps({
        caregiver: {
            type: Object,
            required: true
        },
        start_date: {
            type: [String, null],
            required: true,
        },
        schedule: {
            type: [Object, null],
            required: true
        }
    })

    const defaultPreferenceState = {
        availability_pref_live_in: false,
        availability_pref_live_out: false,
        availability_pref_day_work: false,
        availability_pref_night_work: false,
        availability_pref_fill_ins_flexible: false,
        availability_pref_max_travel_time: 40,
        availability_pref_min_hourly_rate: null,
        availability_pref_min_daily_rate: null,
        availability_pref_live_in_min_days_per_week: 2,
        availability_pref_live_in_max_days_per_week: 7,
        availability_pref_live_out_min_days_per_week: 2,
        availability_pref_live_out_max_days_per_week: 7,
        availability_pref_live_out_min_hours_per_day: 4,
        availability_pref_live_out_max_hours_per_day: 12,
        availability_notes: null
    }

    function defaultScheduleState() {
        return {
            live_in : [
                {
                    id: crypto.randomUUID(),
                    start_time: '09:00',
                    duration: 24 * 60,
                    day_of_week: []
                }
            ],
            live_out: [
                {
                    id: crypto.randomUUID(),
                    start_time: '09:00',
                    duration: 12 * 60,
                    day_of_week: [],
                },
                {
                    id: crypto.randomUUID(),
                    start_time: '09:00',
                    duration: 12 * 60,
                    day_of_week: [],
                }
            ],
        }
    }

    const preferenceState = ref({...defaultPreferenceState})
    const scheduleState = ref(props.schedule ? deepCopy(props.schedule) : defaultScheduleState())

    function extractOutput() {
        const info = deepCopy(preferenceState.value)

        info.availability_pref_max_travel_time = info.availability_pref_max_travel_time ? info.availability_pref_max_travel_time.id : null

        return info
    }

    watch(() => props.caregiver, (newValue) => {
        const newState = deepCopy(newValue)

        if (newState.availability_pref_max_travel_time) {
            newState.availability_pref_max_travel_time = {
                id: parseInt(newState.availability_pref_max_travel_time),
                name: `${newState.availability_pref_max_travel_time} minutes`
            }
        }

        preferenceState.value = newState
    }, {immediate: true, deep: true})

    watch(() => preferenceState.value, (newValue) => {
        emit('preference-data:change', extractOutput(newValue))
    }, {immediate: true, deep: true})

    watch(() => scheduleState, (newValue) => {
        emit('schedule-data:change', scheduleState.value)
    }, {immediate: true, deep: true})

    watch(() => props.schedule, (newValue) => {
        if (newValue == null) {
            scheduleState.value = defaultScheduleState()
            return
        }

        const diff = (getModifiedProperties(JSON.stringify(newValue), JSON.stringify(scheduleState.value)))
        if (Object.keys(diff).length) {
            scheduleState.value = newValue
        }
    }, {immediate: true, deep: true})

    const maxTravelTimes = [{
        id: 20,
        name: '20 minutes'
    }, {
        id: 40,
        name: '40 minutes'
    }, {
        id: 60,
        name: '60 minutes'
    }]

</script>

<template>
    <div class="w-full p-5 flex gap-x-7">

        <div class="flex flex-col w-3/12 h-full gap-y-5 pt-2">

            <div>
                <AreaLabel value="Preferences" />

                <div class="flex w-full gap-2 mb-1">
                    <div class="w-1/2">
                        <Checkbox
                            id="grid-availability_pref_live_in"
                            v-model="preferenceState.availability_pref_live_in"
                            label="Live-In"
                        />
                    </div>
                    <div class="w-1/2">
                        <Checkbox
                            id="grid-availability_pref_day_work"
                            v-model="preferenceState.availability_pref_day_work"
                            label="Days"
                        />
                    </div>
                </div>
                <div class="flex w-full gap-2 mb-2">
                    <div class="w-1/2">
                        <Checkbox
                            id="grid-availability_pref_live_out"
                            v-model="preferenceState.availability_pref_live_out"
                            label="Live-Out"
                        />
                    </div>
                    <div class="w-1/2">
                        <Checkbox
                            id="grid-availability_pref_night_work"
                            v-model="preferenceState.availability_pref_night_work"
                            label="Nights"
                        />
                    </div>
                </div>
                <div class="w-full">
                    <Checkbox
                        id="grid-availability_pref_fill_ins_flexible"
                        v-model="preferenceState.availability_pref_fill_ins_flexible"
                        label="Flexible for Fill-ins"
                    />
                </div>
            </div>
            <div>
                <InputLabel
                    for="grid-availability_pref_max_travel_time"
                    value="Max Travel Time"
                    palette="blue"
                >
                    <LwxSelect
                        class="mt-1"
                        id="grid-availability_pref_max_travel_time"
                        v-model="preferenceState.availability_pref_max_travel_time"
                        :options="maxTravelTimes"
                    />
                </InputLabel>
            </div>
            <div>
                <AreaLabel value="Minimum Rates" />
                <div class="flex gap-2">
                    <InputLabel
                        for="grid-availability_pref_min_hourly_rate"
                        value="Hourly"
                        palette="blue"
                    >
                        <TextInput
                            id="grid-availability_pref_min_hourly_rate"
                            type="text"
                            v-model="preferenceState.availability_pref_min_hourly_rate"
                        />
                    </InputLabel>

                    <InputLabel
                        for="grid-availability_pref_min_daily_rate"
                        value="Daily"
                        palette="blue"
                    >
                        <TextInput
                            id="grid-availability_pref_min_daily_rate"
                            type="text"
                            v-model="preferenceState.availability_pref_min_daily_rate"
                        />
                    </InputLabel>
                </div>
            </div>
            <div>
                <InputLabel
                    for="grid-availability_notes"
                    value="Notes"
                    palette="blue"
                >
                    <textarea
                        id="grid-availability_notes"
                        v-model="preferenceState.availability_notes"
                    />
                </InputLabel>
            </div>
        </div>

        <div class="flex w-3/12 h-full">
            <div class="flex w-full h-full rounded-lg bg-lifeworx-purple-600 bg-opacity-5 border-2 border-dashed border-lifeworx-purple-600 p-5 flex-col gap-y-4">

                <h3 class="text-lifeworx-purple-600 w-full flex font-bold text-xl ">
                    <IconCalendarAdd28Regular class="flex-none flex-shrink-0 inline-flex h-7 w-7 mr-2" />
                    Live-In Shifts
                </h3>
                <div class="">
                    <div class="w-full mb-5">
                        <h4 class="font-semibold text-sm mb-1 text-lifeworx-blue-800">Earliest Start</h4>
                        <TimeRangePicker
                            :startTime="scheduleState.live_in[0].start_time"
                            :duration="scheduleState.live_in[0].duration"
                            stayType="live_in"
                            @update:startTime="(newStartTime) => scheduleState.live_in[0].start_time = newStartTime"
                        />
                        <WeekDaySelect
                            v-model="scheduleState.live_in[0].day_of_week"
                        />
                    </div>
                    <div class="w-full pt-4 border-t border-lifeworx-purple-600">
                        <div class="flex justify-between mb-2">
                            <span>Days per Week</span>
                            <span>{{preferenceState.availability_pref_live_in_min_days_per_week}} - {{preferenceState.availability_pref_live_in_max_days_per_week}}</span>
                        </div>
                        <RangeSlider
                            :min="2"
                            :max="7"
                            :current_min="preferenceState.availability_pref_live_in_min_days_per_week"
                            :current_max="preferenceState.availability_pref_live_in_max_days_per_week"
                            @update:current_min="(value) => preferenceState.availability_pref_live_in_min_days_per_week = value"
                            @update:current_max="(value) => preferenceState.availability_pref_live_in_max_days_per_week = value"
                        />
                    </div>
                </div>

            </div>
        </div>

        <div class="flex w-6/12 h-full flex-col gap-y-5">
            <div class="flex w-full h-full rounded-lg bg-lifeworx-green-600 bg-opacity-5 border-2 border-dashed border-lifeworx-green-600 p-5 flex-col gap-y-4">
                <h3 class="text-lifeworx-green-600 w-full flex font-bold text-xl ">
                    <IconCalendarAdd28Regular class="flex-none flex-shrink-0 inline-flex h-7 w-7 mr-2" />
                    Live-Out Shifts
                </h3>
                <div class="flex w-full gap-5">
                    <div class="w-6/12">
                        <div class="w-full mb-5">
                            <h4 class="font-semibold text-sm mb-1 text-lifeworx-blue-800">Earliest Start - Latest End</h4>
                            <TimeRangePicker
                                :startTime="scheduleState.live_out[0].start_time"
                                :duration="scheduleState.live_out[0].duration"
                                stayType="live_out"
                                @update:startTime="(newStartTime) => scheduleState.live_out[0].start_time = newStartTime"
                                @update:duration="(newDuration) => scheduleState.live_out[0].duration = newDuration"
                            />
                            <WeekDaySelect
                                v-model="scheduleState.live_out[0].day_of_week"
                            />
                        </div>
                        <div class="w-full pt-4 border-t border-lifeworx-green-600">
                            <div class="flex justify-between mb-2">
                                <span>Hours per Day</span>
                                <span>{{preferenceState.availability_pref_live_out_min_hours_per_day}} - {{preferenceState.availability_pref_live_out_max_hours_per_day}}</span>
                            </div>
                            <RangeSlider
                                :min="4"
                                :max="16"
                                :current_min="preferenceState.availability_pref_live_out_min_hours_per_day"
                                :current_max="preferenceState.availability_pref_live_out_max_hours_per_day"
                                @update:current_min="(value) => preferenceState.availability_pref_live_out_min_hours_per_day = value"
                                @update:current_max="(value) => preferenceState.availability_pref_live_out_max_hours_per_day = value"
                            />
                        </div>
                    </div>
                    <div class="w-6/12">
                        <div class="w-full mb-5">
                            <h4 class="font-semibold text-sm mb-1 text-lifeworx-blue-800">Earliest Start - Latest End</h4>
                            <TimeRangePicker
                                :startTime="scheduleState.live_out[1].start_time"
                                :duration="scheduleState.live_out[1].duration"
                                stayType="live_out"
                                @update:startTime="(newStartTime) => scheduleState.live_out[1].start_time = newStartTime"
                                @update:duration="(newDuration) => scheduleState.live_out[1].duration = newDuration"
                            />
                            <WeekDaySelect
                                v-model="scheduleState.live_out[1].day_of_week"
                            />
                        </div>
                        <div class="w-full pt-4 border-t border-lifeworx-green-600">
                            <div class="flex justify-between mb-2">
                                <span>Days per Week</span>
                                <span>{{preferenceState.availability_pref_live_out_min_days_per_week}} - {{preferenceState.availability_pref_live_out_max_days_per_week}}</span>
                            </div>
                            <RangeSlider
                                :min="2"
                                :max="7"
                                :current_min="preferenceState.availability_pref_live_out_min_days_per_week"
                                :current_max="preferenceState.availability_pref_live_out_max_days_per_week"
                                @update:current_min="(value) => preferenceState.availability_pref_live_out_min_days_per_week = value"
                                @update:current_max="(value) => preferenceState.availability_pref_live_out_max_days_per_week = value"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
