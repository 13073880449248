import { computed, reactive, ref } from 'vue';

import { acceptHMRUpdate, defineStore } from 'pinia';
import { useFormStatusStore } from '@/Stores'

import { useNProgress } from '@vueuse/integrations/useNProgress';
import { get } from '@/Helpers/takeLatestResponseRequest';

export const useCaregiverClientShiftStore = defineStore('caregiverClientShift', () => {
    const formStatusStore = useFormStatusStore()

    const { isLoading } = useNProgress()
    const loading = ref(isLoading)

    const shiftDict = reactive({})

    function replaceLocalStoreShifts(caregiver_id, shifts) {
        shiftDict[caregiver_id] = shifts
    }

    function computedShift(caregiver_id) {
        return computed(() => shiftDict[caregiver_id] ? shiftDict[caregiver_id] : [])
    }

    function computedShiftMap(caregiver_id) {
        return computed(() => {
            const map = {}

            if (shiftDict[caregiver_id]) {
                shiftDict[caregiver_id].forEach((shift, index) => {
                    map[shift.id] = index
                })
            }

            return map
        })
    }

    async function retrieveShifts(caregiver_id, lowerBoundDate, upperBoundDate, formId) {
        loading.value = true
        if (formId) {
            formStatusStore.addLoading(formId)
        }

        return await new Promise((resolve, reject) => {
            get(
                `/api/v1/caregivers/${caregiver_id}/client-shifts`,
                {
                    params: {
                        start_date: lowerBoundDate,
                        end_date: upperBoundDate
                    }
                },
            ).then(response => {
                resolve(response)
            }).catch((err) => {
                reject({ "error": err })
            }).finally(() => {
                loading.value = false
                if (formId) {
                    formStatusStore.removeLoading(formId)
                }
            })
        });
    }

    return {
        replaceLocalStoreShifts,
        computedShift, computedShiftMap,
        retrieveShifts
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCaregiverClientShiftStore, import.meta.hot))
}
