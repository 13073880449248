<script setup>

    import { debounce } from '@/Helpers'
    import { computed, watch, onMounted, ref } from 'vue'

    import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption } from '@headlessui/vue'
    import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
    import { useCaregiverStore } from '@/Stores'
    import CaregiverSearchResultItem from '@/Components/Forms/CaregiverSearchResultItem.vue'

    const props = defineProps({
        modelValue: {
            type: [Object, null],
            default: {
                id: undefined,
                first_name: undefined,
                last_name: undefined,
            }
        },
        validity: {
            type: String
        },
        placeholder: {
            type: String,
            default: 'Search Caregivers...'
        },
        input_class: {
            type: String,
            default: 'rounded-t-lg overflow-hidden text-stone-900 placeholder:text-stone-400'
        }
    })

    const caregiverStore = useCaregiverStore()

    const emit = defineEmits(['update:modelValue'])

    const caregiverSearchInput = ref(null)

    const searchInput = ref('')
    const ignoreInputChange = ref(false)
    const searchResults = ref([])
    const selectedCaregiverResult = ref()
    const emptyCaregiver = ref({
        id: undefined,
        first_name: undefined,
        last_name: undefined,
    })

    const caregiverName = computed(() => {
        const { first_name, last_name } = props.modelValue || {};

        if (!first_name && !last_name) {
            return undefined;
        }

        return [first_name, last_name].filter(Boolean).join(' ');
    });


    function selectCaregiver(caregiver) {

        if (!caregiver) {
            emit('update:modelValue', emptyCaregiver.value)
            return;
        }

        ignoreInputChange.value = true
        selectedCaregiverResult.value = caregiver
        searchInput.value = caregiver.first_name + ' ' + caregiver.last_name
        emit('update:modelValue', selectedCaregiverResult.value)
        searchResults.value = []
        ignoreInputChange.value = false
    }

    const debouncedSearch = debounce(async (newValue) => {
        if (newValue && newValue.length >= 2 && !ignoreInputChange.value) {
            searchResults.value = await caregiverStore.findCaregiversFromSearch(newValue);
        } else {
            searchResults.value = []
            emit('update:modelValue', emptyCaregiver.value)
        }
    }, 500)

    onMounted(async () => {

        watch(searchInput, async (newValue) => {
            debouncedSearch(newValue)
        })

    })

</script>

<template>

    <Combobox
        as="div"
        :modelValue="caregiverName"
        @update:modelValue="selectCaregiver"
        nullable
        class="relative"
    >
        <div>
            <div class="relative">
                <ComboboxInput
                    ref="caregiverSearchInput"
                    class="w-full py-1.5 sm:text-sm placeholder:text-stone-400 border border-stone-300 border-b-0 focus:ring-0 focus:border-lifeworx-blue-600"
                    :class="props.input_class"
                    :placeholder="props.placeholder"
                    @change="searchInput = $event.target.value"
                    autocomplete="off"
                />

                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <MagnifyingGlassIcon class="h-5 w-5 text-lifeworx-blue-600" aria-hidden="true" />
                </ComboboxButton>
            </div>

            <ComboboxOptions
                class="absolute z-popover w-full max-h-[32rem] overflow-y-scroll rounded-md rounded-t-none bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >

                <ComboboxOption
                    v-for="(result, i) in searchResults"
                    :key="i"
                    :value="result"
                    class="group bg-stone-200 bg-opacity-50 odd:bg-stone-300 odd:bg-opacity-60 hover:bg-opacity-100 hover:bg-zinc-300 cursor-pointer"
                    v-slot="{ active }"
                >

                    <CaregiverSearchResultItem :caregiver="result" />

                </ComboboxOption>

            </ComboboxOptions>

        </div>

    </Combobox>

</template>
